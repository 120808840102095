'use client';

import React, { useState, useEffect } from 'react';
import {
  TextBody,
  TextTitle,
  vars,
  ButtonGroup,
  ButtonPrimary,
  ButtonSecondary,
  Flex,
  Banner,
  type BannerProps,
} from '@knapsack/toby';
import { openUrlInTab } from '@/utils/url-utils';
import { KnapsackWordmark } from '../../components/knapsack-logo/knapsack-logo';
import {
  errorPage,
  errorPageContent,
  errorPageGraphic,
  errorPageText,
} from './error-page.css';

type Props = {
  title: string;
  message?: React.ReactNode;
  testId?: string;
  banner?: BannerProps;
  actions?: {
    // @todo add proper `key` prop - currently uses `label`
    label: string;
    /** Either link (string) or function */
    onClick: string | (() => void);
    loading?: boolean;
  }[];
  graphic?:
    | 'auth-error'
    | 'site-id-not-found'
    | 'no-access'
    | 'no-connection'
    | 'private-workspace'
    | 'update-required'
    | 'access-denied'
    | 'no-sites'
    | 'unknown';
};

export const ErrorPage = ({
  graphic,
  title,
  message,
  actions,
  testId,
  banner,
}: Props) => {
  const [graphicUrl, setGraphicUrl] = useState('');

  useEffect(() => {
    switch (graphic) {
      case 'auth-error': {
        import('../../assets/illustrations/error/auth-error.image.svg').then(
          (mod) => setGraphicUrl(mod.default),
        );
        break;
      }
      case 'site-id-not-found': {
        import(
          '../../assets/illustrations/error/site-id-not-found.image.svg'
        ).then((mod) => setGraphicUrl(mod.default));
        break;
      }
      case 'no-access': {
        import('../../assets/illustrations/error/no-access.image.svg').then(
          (mod) => setGraphicUrl(mod.default),
        );
        break;
      }
      case 'no-connection': {
        import('../../assets/illustrations/error/no-connection.image.svg').then(
          (mod) => setGraphicUrl(mod.default),
        );
        break;
      }
      case 'private-workspace': {
        import(
          '../../assets/illustrations/error/private-workspace.image.svg'
        ).then((mod) => setGraphicUrl(mod.default));
        break;
      }
      case 'update-required': {
        import(
          '../../assets/illustrations/error/update-required.image.svg'
        ).then((mod) => setGraphicUrl(mod.default));
        break;
      }
      case 'access-denied': {
        import('../../assets/illustrations/error/access-denied.image.svg').then(
          (mod) => setGraphicUrl(mod.default),
        );
        break;
      }
      case 'no-sites': {
        import('../../assets/illustrations/error/no-sites.image.svg').then(
          (mod) => setGraphicUrl(mod.default),
        );
        break;
      }
      case 'unknown': {
        import('../../assets/illustrations/error/unknown.image.svg').then(
          (mod) => setGraphicUrl(mod.default),
        );
        break;
      }
      default: {
        const _exhaustiveCheck: never = graphic;
        // using the 'unknown' graphic as a default
        import('../../assets/illustrations/error/unknown.image.svg').then(
          (mod) => setGraphicUrl(mod.default),
        );
        break;
      }
    }
  }, [graphic]);

  return (
    <main className={errorPage} data-test-id={testId}>
      <div className={errorPageContent}>
        {graphicUrl && (
          <img
            className={errorPageGraphic}
            src={graphicUrl}
            alt="error-graphic"
          />
        )}
        <div className={errorPageText}>
          <div
            style={{
              color: vars.color.text.subtle,
              marginBottom: vars.spacing.component.large,
            }}
          >
            <KnapsackWordmark />
          </div>
          <div data-test-id="errorPageMessage">
            <Flex direction="column" gap="large" align="stretch">
              <TextTitle size="small" spacing="none">
                {title}
              </TextTitle>
              {message && React.isValidElement(message) && message}
              {message && !React.isValidElement(message) && (
                <TextBody size="large">{message}</TextBody>
              )}
              {banner && <Banner {...banner} />}
              {actions?.length > 0 && (
                <ButtonGroup justify="start">
                  {actions.map(({ label, onClick, loading }, i) => {
                    const onTrigger =
                      typeof onClick === 'string'
                        ? () => openUrlInTab(onClick)
                        : onClick;
                    return i === 0 ? (
                      <ButtonPrimary
                        key={label}
                        label={label}
                        onTrigger={onTrigger}
                        loading={loading}
                      />
                    ) : (
                      <ButtonSecondary
                        key={label}
                        onTrigger={onTrigger}
                        label={label}
                        loading={loading}
                      />
                    );
                  })}
                </ButtonGroup>
              )}
            </Flex>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ErrorPage;
