'use client';

import { useTrackPageTypeView } from '@/utils/analytics';
import { TextBody, InputSelect, TextHeading } from '@knapsack/toby';
import { TextLink } from '@/components/text-link';
import { sendAppEvent, useAppCtxSelector, useAppStateMatches } from '@/core';
import { useRouter } from 'next/navigation';
import { LoggedInRoute, useKsRouter } from '@/utils/routes';
import { PageLayout } from '@/core/layout/page-layout';
import { SectionLayout } from '@/core/layout/section-layout';
import { ErrorPage } from '@/core/pages/error-page';
import { appLandingPage, appLandingPageContent } from './app-landing.page.css';
import { AppHeader } from '../core/layout/app-header/app-header';

const AppLandingPage = () => {
  useTrackPageTypeView({
    pageName: 'App Home',
  });
  const isLoggedIn = useAppStateMatches('user.loggedIn');

  const { goToFullPath } = useKsRouter();
  const router = useRouter();
  const userSites = useAppCtxSelector(
    ({ user }) => user?.membershipSiteIds || [],
  );

  const selectOptions = [
    { label: 'Choose a workspace...', value: '' },
    ...userSites.map((site) => {
      return { label: site, value: site };
    }),
  ];

  if (userSites.length === 1) {
    router.replace(`/site/${userSites[0]}/latest`);
    return;
  }

  return (
    <SectionLayout header={isLoggedIn && <AppHeader />} removeGutter>
      <LoggedInRoute>
        <PageLayout
          metaTags={{ title: 'Knapsack' }}
          trackInfo={{ pageName: 'App Home' }}
          removePadding
        >
          {userSites.length === 0 && (
            <ErrorPage
              graphic="no-sites"
              title="Looks like you don't have access to any workspaces"
              message={
                <TextBody size="large">
                  Ask an admin at your organization for an invite, or{' '}
                  <TextLink
                    external
                    href="https://help.knapsack.cloud/article/23-getting-help"
                  >
                    get in touch
                  </TextLink>{' '}
                  with us.
                </TextBody>
              }
              actions={[
                {
                  label: 'Log Out',
                  onClick: () => sendAppEvent('user.signOut'),
                },
              ]}
            />
          )}

          {userSites.length > 1 && (
            <main className={appLandingPage}>
              <div className={appLandingPageContent}>
                <TextHeading size="large">Choose a workspace</TextHeading>
                <InputSelect
                  options={selectOptions}
                  onChange={(siteId) => {
                    goToFullPath(`/site/${siteId}/latest`);
                  }}
                  value=""
                  label="Workspace List"
                />
              </div>
            </main>
          )}
        </PageLayout>
      </LoggedInRoute>
    </SectionLayout>
  );
};

export default AppLandingPage;
